import React, { useEffect, useRef } from 'react';
import { Marker } from 'react-leaflet';
import { Icon } from 'leaflet';
import arrow from '../../assets/caret-up.svg';
import 'leaflet-rotatedmarker';


export const RotatingMarker = ({ position, rotationAngle }) => {
    const markerRef = useRef();
    //console.log(position, rotationAngle);

    const myIcon = new Icon({
        iconUrl: arrow,
        iconSize: [32, 32]
    });

    useEffect(() => {
        if (markerRef.current && rotationAngle !== null) {
            const marker = markerRef.current;
            marker.setRotationAngle(rotationAngle);
        }
    }, [rotationAngle]);

    //console.log(position, rotationAngle);
    if (!position) return null;
    return (
        <Marker ref={markerRef} position={[position.latitude, position.longitude]} icon={myIcon} />
    );
};
