import React, { Component } from 'react';

import { Route, Routes } from "react-router-dom";
import { SimpleLayout } from "./components/Layouts/SimpleLayout";
import  DualView  from "./pages/dual-view.jsx";

export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      defferedPrompt: null
    };

    this.contentRef = React.createRef();
  }

  componentDidMount() { }

  handleTouch() { }

  render() {
    return (
      <>
        
            <div ref={this.contentRef} onTouchStart={this.handleTouch}>
              <Routes>
                <Route exact path="/" element={<SimpleLayout />}>
                  <Route index element={<DualView />} />
                  {/* <Route path="/find-nearby" element={<FindNearby />} />  */}
                </Route>
              </Routes>
            </div>
          </>
       
      
    );
  }
}
