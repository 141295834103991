/* eslint-disable no-unused-vars */
export const getOrientation = () => {
    if (window.localStorage.getItem("Orientation")) {
        return Number(window.localStorage.getItem("Orientation"));
    }
    else
        return true;
}

export const setOrientation = (data) => {
    try {
        window.localStorage.setItem("Orientation", data);
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

