import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { RecenterAutomatically } from '../components/Map/Recenter';
import { RotatingMarker } from '../components/Map/RotatingMarker';
import 'leaflet-rotatedmarker';
import { useWakeLock } from '../helpers/WakeLock';
import {setOrientation, getOrientation} from '../helpers/Lookup';

const topoMap = {
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://opentopomap.org">OpenTopoMap</a> contributors',
    maxZoom: 17
};

const streetMap = {
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
    maxZoom: 19
};


const DualView = () => {

    //https://react-leaflet.js.org/docs/example-layers-control/

    useWakeLock();
    const mapRef = useRef(null);
    const [position, setPosition] = useState({ latitude: -26.0172312, longitude: 28.0303184 });
    const [markerPosition, setMarkerPosition] = useState(position);
    const [rotationAngle, setRotationAngle] = useState(0);
    const [speed, setSpeed] = useState(null);
    const [isPortrait, setIsPortrait] = useState(getOrientation());

    const debounce = (fn, ms) => {
        let timer;
        return () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                fn.apply(this);
            }, ms);
        };
    };

    const useMockGeolocation = (setPosition) => {
        useEffect(() => {
            let watchId;

            if ('geolocation' in navigator) {
                let latitude = -26.0172312;
                let longitude = 28.0303184;
                const speed = 0.0001; // Change per update
                const updatePosition = () => {
                    latitude += speed;
                    longitude += speed;
                    setPosition({ latitude, longitude, accuracy: 10 });
                };

                watchId = setInterval(updatePosition, 1000);

                return () => {
                    clearInterval(watchId);
                };
            } else {
                console.warn('Geolocation is not supported by this browser.');
            }
        }, [setPosition]);
    };

    const useLiveLocation = (setPosition) => {  
        useEffect(() => {
            if (navigator.geolocation) {
                const watcher = navigator.geolocation.watchPosition(
                    (position) => {
                        //console.log(position.coords);
                        const { latitude, longitude, heading, speed, accuracy } = position.coords;
                        const updatePosition = {
                            latitude : latitude,
                            longitude: longitude,
                            accuracy: accuracy
                        };
                        setPosition(updatePosition);
                        setRotationAngle(heading || 0);
                        setSpeed(speed || 0);
                    },
                    (error) => {
                        console.error(error.message);
                    },
                    {
                        enableHighAccuracy: true,
                        maximumAge: 0,
                        timeout: 27000
                    }
                );
                return () => {
                    navigator.geolocation.clearWatch(watcher);
                };
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        }, []);

    };

    const toggleOrientation = () => {
        const portait = !isPortrait;
        setIsPortrait(portait);
        setOrientation(portait);
    };
    
    useLiveLocation(setPosition);
    //useMockGeolocation(setPosition);
    return (
        <>
            <div className={isPortrait ? "block-portrait map-border-bottom" : "block-landscape map-border-right"}>
                <div className='locationmap-container'>
                    <MapContainer center={[-26.0172312, 28.0303184]} zoom={13} scrollWheelZoom={true} className='map' preferCanvas={true} touchZoom={true} zoomAnimation={true} >
                        <TileLayer attribution={streetMap.attribution} url={streetMap.url} maxZoom={streetMap.maxZoom} />
                        <RotatingMarker position={markerPosition} rotationAngle={rotationAngle} />
                        <RecenterAutomatically position={position} setPosition={setMarkerPosition} setSpeed={setSpeed} setHeading={setRotationAngle} />
                    </MapContainer>
                </div>
            </div>
            <div className={isPortrait ? "block-portrait" : "block-landscape"}>
                <div className='locationmap-container'>
                    <MapContainer center={[-26.0172312, 28.0303184]} zoom={19} scrollWheelZoom={true} className='map' preferCanvas={true} touchZoom={true} zoomAnimation={true} whenCreated={(mapInstance) => { mapRef.current = mapInstance; }} >
                        <TileLayer attribution={streetMap.attribution} url={streetMap.url} maxZoom={streetMap.maxZoom} />
                        <RotatingMarker position={markerPosition} rotationAngle={rotationAngle} />
                        <RecenterAutomatically position={position} setPosition={setMarkerPosition} setSpeed={setSpeed} setHeading={setRotationAngle} />
                    </MapContainer>
                </div>
            </div>
            <div className='speed-indicator'>
                {(speed * 3.6).toFixed(0)}
            </div>
            <div className='direction-indicator'>
                {rotationAngle.toFixed(0)}&deg;
            </div>
            <div className='orientation-button' onClick={toggleOrientation}>
                <i className={`fa-regular ${isPortrait ? 'fa-rectangle-wide' : 'fa-rectangle-vertical'}`}></i>
            </div>
        </>
    );
};
export default DualView;
