import { useState, useEffect } from 'react';
import { useMap } from 'react-leaflet';

export const RecenterAutomatically = ({ position, setPosition, setSpeed, setHeading }) => {
    const map = useMap();
    
const [oldPosition, setOldPosition] = useState(null);

    //console.log(position);
    const smoothTransition = (start, end, setPosition) => {
        const duration = 1000; // transition duration in milliseconds
        const startTime = performance.now();

        const animate = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const t = Math.min(elapsedTime / duration, 1); // normalize time

            const newPosition = {
                latitude: start.latitude + t * (end.latitude - start.latitude),
                longitude: start.longitude + t * (end.longitude - start.longitude)
            };

            if (setPosition)
                setPosition(newPosition);
            
            if (newPosition)
                map.flyTo([newPosition.latitude, newPosition.longitude], map.getZoom(), { animate: false });

            if (t < 1) {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);
    };

    useEffect(() => {
        if (position)            {
            if (oldPosition) {
                smoothTransition(oldPosition, position, setPosition);
            } else {
                smoothTransition(position, position, setPosition);
                //setPosition(position);
            }
            setOldPosition(position);
        }
    }, [position]);



    return null;
};